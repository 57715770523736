import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { ApplicationService } from './application.service';
import { ErrorHandlerService } from './error-handler.service';
import { SignUpPersonal } from '../models/sign-up-personal.model';
import { GoesDate } from '../models/date.model';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class SignUpService {

    private signUp = new SignUpPersonal();
    private callbackUrl : string;

    constructor(
      private http : HttpClient,
      private router: Router,
      private errorHandler : ErrorHandlerService,
      private applicationService : ApplicationService,
      private authService: AuthService){}

    resetSignUp() : void {
        this.signUp = new SignUpPersonal();
    }

    getSignUp() : SignUpPersonal{
        return this.signUp;
    }

    resetCallback() : void{
        this.callbackUrl = "";
    }

    getCallback() : string{
        return this.callbackUrl;
    }

    setCallback(url : string) : void{
        this.callbackUrl = url;
    }

    updateUser(user : SignUpPersonal, linkGES?: boolean) : Observable<any>{
        if(user){
            let dateString = user.dateOfBirth.getSimpleString();
            let userRequest = {
              "userId": user.userId,
              "lastName": user.lastName,
              "firstName": user.firstName,
              "middleName": user.middleName,
              "countryOfBirth": user.countryOfBirth,
              "stateOfBirth": user.stateOfBirth,
              "dateOfBirth": dateString,
              "cityOfBirth": user.cityOfBirth,
              "emailAddress": user.emailAddress,
              "membershipId": user.passId,
              "goesUserId" : user.goesId,
              "fastId" : user.fastId,
              "phones": user.phoneNumbers,
              "passIdOptoutInd" : (user.goesId === '' || user.passId || user.fastId? false : true),
              "linkAccountFlag" : (user.goesId === '' || user.passId || user.fastId? true : false)  //link to GES for account creation.
            };
            return this.http.put(environment.uriEndpoint + environment.apiVersion + 'goesapp/users/' + user.userId, userRequest).pipe(
                map((response:any) => {
                    let res = response;
                    if (this.errorHandler.checkErrorCode(res)) {
                        //update to redirect somewhere
                        if(!(user.goesId && (user.maxAttempt != 3  || user.maxDocAttempt != 3) && !user.passId && !user.fastId)){
                            if(!res.linkedAccountEmail){
                                this.router.navigate([this.callbackUrl], { queryParams: {userId : user.userId}});
                                this.resetCallback();
                            }
                        }
                        return res;
                    }
                    return null;
                }),
                catchError(error=> this.errorHandler.handleError(error)));
        }
        return null;
    }


    validateSecurityQuestions(user : SignUpPersonal) : Observable<any>{

        if(user){
        let dateString = user.dateOfBirth.getSimpleString();
        let loggedInUser = this.authService.getUser();

            let userRequest = {
              "userId": user.userId,
              "lastName": user.lastName,
              "firstName": user.firstName,
              "middleName": user.middleName,
              "countryOfBirth": user.countryOfBirth,
              "stateOfBirth": user.stateOfBirth,
              "dateOfBirth": dateString,
              "cityOfBirth": user.cityOfBirth,
              "questions" : user.questionList,
              "emailAddress": user.emailAddress,
              "membershipId": user.passId,
              "goesUserId" : user.goesId,
              "fastId" : user.fastId,
              "phones": user.phoneNumbers,
              "emailOptoutInd" : user.emailOptoutInd,
              "passIdOptoutInd" : true,
              "linkAccountFlag" : false  //link to GES for account creation.

            };
            return this.http.post(environment.uriEndpoint + environment.apiVersion + 'goesapp/users/validateSeqQuestion', userRequest).pipe(
                map((response:any) => {
                    let res = response;
                    return res;
                }),
                catchError(error=> this.errorHandler.handleError(error)));
        }
        return null;

    }


    createUser(user : SignUpPersonal) : Observable<any>{


        if(user){
            let dateString = user.dateOfBirth.getSimpleString();
            let loggedInUser = this.authService.getUser();
            let userRequest = {
              "userId": user.userId,
              "lastName": user.lastName,
              "firstName": user.firstName,
              "middleName": user.middleName,
              "countryOfBirth": user.countryOfBirth,
              "stateOfBirth": user.stateOfBirth,
              "dateOfBirth": dateString,
              "cityOfBirth": user.cityOfBirth,
              "emailAddress": user.emailAddress,
              "membershipId": user.passId,
              "goesUserId" : user.goesId,
              "fastId" : user.fastId,
              "phones": user.phoneNumbers,
              "emailOptoutInd" : user.emailOptoutInd,
              "passIdOptoutInd" : (user.goesId === '' ? false : true),
              "linkAccountFlag" : (user.goesId === '' ? true : false)  //link to GES for account creation.

            };
            return this.http.post(environment.uriEndpoint + environment.apiVersion + 'goesapp/users/', userRequest).pipe(
                map((response:any) => {
                    let res=response;
                    if(this.errorHandler.checkErrorCode(res)){
                        if(res.linkedAccountEmail || user.goesId){
                            //DO NOTHING STAY ON ACCOUNT
                        }
                        else if(user.passId || user.fastId) {
                          this.router.navigate(['/dashboard']);
                        }
                        else {this.router.navigate(['/getstarted', { stepDone: 3 }]);}
                        return res;
                    }
                }),
                catchError(error=> this.errorHandler.handleError(error)));

        }
        return null;
    }

    fetchDocList(user : SignUpPersonal) : Observable<any> {
        if(user){
            let dateString = user.dateOfBirth.getSimpleString();
            let loggedInUser = this.authService.getUser();
            let userRequest = {
              "userId": user.userId,
              "lastName": user.lastName,
              "firstName": user.firstName,
              "middleName": user.middleName,
              "countryOfBirth": user.countryOfBirth,
              "stateOfBirth": user.stateOfBirth,
              "dateOfBirth": dateString,
              "cityOfBirth": user.cityOfBirth,
              "emailAddress": user.emailAddress,
              "membershipId": user.passId,
              "goesUserId" : user.goesId,
              "fastId" : user.fastId,
              "phones": user.phoneNumbers,
              "emailOptoutInd" : user.emailOptoutInd,
              "passIdOptoutInd" : (user.goesId === '' ? false : true),
              "linkAccountFlag" : (user.goesId === '' ? true : false)  //link to GES for account creation.

            };
        let endpoint : string = 'goesapp/users/documents/';
        return this.http.post(environment.uriEndpoint + environment.apiVersion + endpoint, userRequest).pipe(
            map((response:any) => {
                if(response){
                    let res=response;
                    if(this.errorHandler.checkErrorCode(res)){
                        //update to redirect somewhere
                        if(res.docTypes){
                            res.docTypes = this.filterDocumentTypes(res.docTypes);
                        }
                        return res;
                    }
                }
                return null;
            }),
            catchError(error => this.errorHandler.handleError(error)));

        }
        return null;
     }

     validateDocInfo(user : SignUpPersonal) : Observable<any> {

        if(user){
            let dateString = user.dateOfBirth.getSimpleString();
            let loggedInUser = this.authService.getUser();

            let userRequest = {
                "userId": user.userId,
                "lastName": user.lastName,
                "firstName": user.firstName,
                "middleName": user.middleName,
                "countryOfBirth": user.countryOfBirth,
                "stateOfBirth": user.stateOfBirth,
                "dateOfBirth": dateString,
                "cityOfBirth": user.cityOfBirth,
                "questions" : user.questionList,
                "emailAddress": user.emailAddress,
                "membershipId": user.passId,
                "goesUserId" : user.goesId,
                "fastId" : user.fastId,
                "phones": user.phoneNumbers,
                "emailOptoutInd" : user.emailOptoutInd,
                "passIdOptoutInd" : true,
                "linkAccountFlag" : false,  //link to GES for account creation.
                "documentInfo" : user.documentInfo
            };

            let endpoint : string = 'goesapp/users/validateDocument';
            return this.http.post(environment.uriEndpoint + environment.apiVersion + endpoint, userRequest).pipe(
                map((response:any) => {
                    if(response){
                        let res=response;
                        if(this.errorHandler.checkErrorCode(res)){
                            return res;
                        }
                    }
                    return null;
                }),
                catchError(error => this.errorHandler.handleError(error)));
            }
            return null;
     }

     //for GOESID security flow that finds duplicate
    transferMembership(user : SignUpPersonal) : Observable<any> {
        if(user){
            let dateString = user.dateOfBirth.getSimpleString();
            let loggedInUser = this.authService.getUser();

            let userRequest = {
                "userId": user.userId,
                "lastName": user.lastName,
                "firstName": user.firstName,
                "middleName": user.middleName,
                "countryOfBirth": user.countryOfBirth,
                "stateOfBirth": user.stateOfBirth,
                "dateOfBirth": dateString,
                "cityOfBirth": user.cityOfBirth,
                "questions" : user.questionList,
                "emailAddress": user.emailAddress,
                "membershipId": user.passId,
                "goesUserId" : user.goesId,
                "fastId" : user.fastId,
                "phones": user.phoneNumbers,
                "emailOptoutInd" : user.emailOptoutInd,
                "passIdOptoutInd" : true,
                "linkAccountFlag" : false,  //link to GES for account creation.
                "documentInfo" : user.documentInfo
            };
         let endpoint : string = 'goesapp/users/transferMembership';

         return this.http.post(environment.uriEndpoint + environment.apiVersion + endpoint, userRequest).pipe(
            map((response:any) => {
                if(response){
                    return response;
                }
                return null;
            }),
            catchError(error => this.errorHandler.handleError(error)));
        }
        return null;
     }


    getUser(userId : string): Observable<any>{

        return this.http.get(environment.uriEndpoint + environment.apiVersion + 'goesapp/users/' + userId).pipe(
            map((response:any) => {
                let res=response;
                if(this.errorHandler.checkErrorCode(res)){
                     this.mapUser(res);
                     return res;
                }
                return null;
            }),
            catchError(error=> this.errorHandler.handleError(error)));
    }

    filterDocumentTypes( docArray : Array<string>) : Array<string>{

        let uniqueArray = docArray.filter(function(item, pos) {
             return docArray.indexOf(item) == pos;
        });
        return uniqueArray;
    }

    updateApplication ( data : any) : void {
        if(data && this.applicationService.getApplication()){
            let personal = this.applicationService.getApplication().person;
            personal.lastName = data.lastName;
            personal.firstName = data.firstName;
            personal.middleName = data.middleName;
            personal.dob = data.dateOfBirth;
            personal.birthCity = data.cityOfBirth;
            personal.birthStateCode = data.stateOfBirth;
            personal.birthCountryCode  = data.countryOfBirth;
            personal.email = data.emailAddress;
            personal.phoneNumbers = data.phones;

        }
    }

    mapUser(data: any): void {
        if (data) {
            this.signUp.goesId = ""; // initialize to empty
            this.signUp.fastId = ""; // initialize to empty
            this.signUp.lastName = data.lastName;
            this.signUp.firstName = data.firstName;
            this.signUp.middleName = data.middleName;
            this.signUp.cityOfBirth = data.cityOfBirth;
            this.signUp.countryOfBirth = data.countryOfBirth;
            this.signUp.emailAddress = data.emailOptoutInd ? '' : data.emailAddress;
            this.signUp.passId = data.membershipId;
            this.signUp.phoneNumbers = data.phones;
            this.signUp.stateOfBirth = data.stateOfBirth;
            this.signUp.emailOptoutInd   = data.emailOptoutInd;
            this.signUp.maxAttempt = data.maxAttempt;
            this.signUp.maxDocAttempt = data.maxDocAttempt;
            this.signUp.linkedAccountEmail = data.linkedAccountEmail;
            this.signUp.dateOfBirth = new GoesDate(null, null, null, data.dateOfBirth);
            if (data.userId && data.userId !== "") {
              this.signUp.userId = data.userId;
            }
        }
    }

}
