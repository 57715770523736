import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ErrorHandlerService } from '../services/error-handler.service';
import { LogService } from '../services/log.service';
import { Vehicle } from '../models/vehicle.model';
import { ManageVehicle } from '../models/manage-vehicle.model';

import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SETTINGS } from './../../app.constants';

@Injectable()
export class VehicleService {

    private _vehicles: Vehicle[];
    private _manageVehicles: ManageVehicle[];
    private _confirmVehicle: ManageVehicle;

    constructor(
        private http: HttpClient,
        private errorHandler: ErrorHandlerService,
        private logService: LogService){
        this._vehicles = new Array<Vehicle>();
        this._manageVehicles = new Array<ManageVehicle>();
    }

    setConfirmVehicle(veh: ManageVehicle){
        this._confirmVehicle = veh;
    }

    getConfirmVehicle(): ManageVehicle{
        return this._confirmVehicle;
    }

    pullMakes(): any {
        // temporary hardcoded list. might eventually come from  backend
        const tempMakes: any = [
            {name: 'Acura'},
            {name: 'Alfa Romeo'},
            {name: 'Aston Martin'},
            {name: 'Audi'},
            {name: 'Bentley'},
            {name: 'BMW'},
            {name: 'Bugatti'},
            {name: 'Buick'},
            {name: 'Cadillac'},
            {name: 'Chevrolet'},
            {name: 'Chrysler'},
            {name: 'Citroen'},
            {name: 'Dodge'},
            {name: 'Ferrari'},
            {name: 'Fiat'},
            {name: 'Ford'},
            {name: 'Geely'},
            {name: 'General Motors'},
            {name: 'GMC'},
            {name: 'Honda'},
            {name: 'Hyundai'},
            {name: 'Infiniti'},
            {name: 'Jaguar'},
            {name: 'Jeep'},
            {name: 'Kia'},
            {name: 'Koenigsegg'},
            {name: 'Lamborghini'},
            {name: 'Land Rover'},
            {name: 'Lexus'},
            {name: 'Maserati'},
            {name: 'Mazda'},
            {name: 'McLaren'},
            {name: 'Mercedes-Benz'},
            {name: 'Mini'},
            {name: 'Mitsubishi'},
            {name: 'Nissan'},
            {name: 'Pagani'},
            {name: 'Peugeot'},
            {name: 'Porsche'},
            {name: 'Ram'},
            {name: 'Renault'},
            {name: 'Rolls Royce'},
            {name: 'Saab'},
            {name: 'Subaru'},
            {name: 'Suzuki'},
            {name: 'Tata Motors'},
            {name: 'Tesla'},
            {name: 'Toyota'},
            {name: 'Volkswagen'},
            {name: 'Volvo'}
        ];

        return tempMakes;
    }

    /* checks for duplicate VIN numbers in a collection of vehicles
     * returns false if no duplicates
     * returns VIN, when a duplicate is found
     */
    checkDuplicateVINs(vehicles?: Array<Vehicle>): any {
        const vins = new Array<string>();
        let returnedVin: string;
        if (!vehicles) {
            vehicles = this._vehicles;
        }
        vehicles.forEach(vehicle => {
            if (vins.indexOf(vehicle.vin) === -1){
                // vehicle vin is not a duplicate, so add it to our bucket
                vins.push(vehicle.vin);
            } else {
                // vehicle vin was already in our bucket, so duplicate found
                returnedVin = vehicle.vin;
            }
        });
        return returnedVin ? returnedVin : false;
    }

    /*get applicant's list of VIN numbers to check for pre-existing (from GES and TTP) */
    getVINs(userId: string): Observable<any> {
        const requestURI = environment.uriEndpoint + 'v1/goesapp/dashboard/managevehicles/getapplicantvins/' + userId;
        return this.http.get(requestURI).pipe(
            map((res: any) => {
                const data = res;
                if (this.errorHandler.checkErrorCode(data)){
                    return data;
                }
                return null;
            }));
    }

    /* get user's registered vehicles from GES */
    getVehicles(userId: string, passId: string, forceStatic?: boolean): Observable<any>{
        // userId = '00000000000';
        // passId = '779092122'; // testing
        if (userId && passId){
            let requestURI = '';

            if (SETTINGS.useStaticTestAPIData || forceStatic) {
              requestURI = SETTINGS.staticTestAPIRoot + 'vehicle-data.json';
            } else {
                requestURI = environment.uriEndpoint + 'v1/goesapp/dashboard/managevehicles/getgesvehicles/' + userId;
                requestURI += '?passId=' + passId;
            }
            return this.http.get(requestURI).pipe(
                map((res: any) => {
                    this._manageVehicles = new Array<ManageVehicle>();
                    const data = res;
                    if (data.hasOwnProperty('vehicles')) {
                        if (data.vehicles !== null) {
                            data.vehicles.forEach((vehicle: any) => {
                                const tempVehicle = new ManageVehicle(vehicle);
                                this._manageVehicles.push(tempVehicle);
                            });
                        }
                    }
                    return this._manageVehicles;
                }),
                catchError(error => this.errorHandler.handleError(error)));
        } else {
            this.logService.error('GetVehicles - Missing params (userId, passId) > ' + userId + ', ' + passId);
            return of(false);
        }
    }

    /* update user's registered vehicle in GES */
    updateVehicle(userId: string, passId: string, vehicle: any, forceStatic?: boolean): Observable<any>{
        if (userId && passId && vehicle){
            let requestURI = environment.uriEndpoint + 'v1/goesapp/dashboard/managevehicles/updatevehicle/' + userId;
            requestURI += '?passId=' + passId;
            const body = {
              color: vehicle.color,
              id: vehicle.gesId,
              isGovernmentIssuedPlate: vehicle.govtLicensePlate === 'Y' ? true : false,
              licenseCountryOfIssuance: vehicle.licenseCountryOfIssuance,
              licensePlateNumber: vehicle.licensePlateNumber,
              make: vehicle.make,
              model: vehicle.model,
              ownerType: vehicle.owner,
              stateOfIssuance: vehicle.stateProvinceOfIssuance,
              vin: vehicle.vin,
              year: vehicle.year
            };
            return this.http.put(requestURI, body).pipe(
                catchError(error => this.errorHandler.handleError(error)));
        } else {
            this.logService.error('updateVehicles - Missing params (userId, passId or vehicle) > ' + userId + ', ' + passId);
            return of(false);
        }
    }

    /**
     * Creates a vehicle termination application.
     *
     * @param  {string}  userId  [description]
     * @param  {Vehicle} vehicle [description]
     * @return {[type]}          [description]
     */
    terminateVehicle(userId: string, vehicle: any) {
        if (userId && vehicle) {
            const requestURI = environment.uriEndpoint + 'v1/goesapp/dashboard/managevehicles/updatevehicle/' + userId;
            const body = {
              color: vehicle.color,
              id: vehicle.gesId,
              isGovernmentIssuedPlate: vehicle.govtLicensePlate === 'Y' ? true : false,
              licenseCountryOfIssuance: vehicle.licenseCountryOfIssuance,
              licensePlateNumber: vehicle.licensePlateNumber,
              make: vehicle.make,
              model: vehicle.model,
              ownerType: vehicle.owner,
              stateOfIssuance: vehicle.stateProvinceOfIssuance,
              vin: vehicle.vin,
              year: vehicle.year
            };
            return this.http.put(requestURI, body).pipe(
                catchError(error => this.errorHandler.handleError(error)));
        } else {
            this.logService.error('updateVehicles - Missing params (userId, passId or vehicle) > ' + userId);
            return of(false);
        }
    }
}
