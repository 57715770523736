import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Application } from '../models/application.model';
import { ErrorHandlerService } from '../services/error-handler.service';
import { Country } from '../models/country.model';
import { TranslateReferenceService } from '../services/translate-reference.service';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class ViewMembershipInfoService {

  constructor(
    private http : HttpClient,
    private errorHandler : ErrorHandlerService,
    private refService: TranslateReferenceService) {}

  loadMembershipInformation(userId : string) : Observable<Application> {

    if (!userId) {
      return this.errorHandler.handleError('Either user Id (' + userId + ') is not valid.');
    }

    let requestURI = environment.uriEndpoint + 'v1/goesapp/users/' + userId + '/ges/membership';

    return this.http.get(requestURI).pipe(
      map((res:any) => {
        if (res) {
            let app = new Application(res);

            // NOTE Setting request type to MBI
            app.requestType = 'MBI';

            if (app.driversLicense.countryOfIssuance.isoCountryCode) {
              app.driversLicense.countryOfIssuance = this.refService.getCountryByIsoCode(app.driversLicense.countryOfIssuance.isoCountryCode);
            }

            app.addresses.forEach(address => {
              if (address.country && address.country.isoCountryCode) {
                address.country = this.refService.getCountryByIsoCode(address.country.isoCountryCode);
              }
            });

            app.citizenshipDocuments.forEach(citDocs => {
              citDocs.country = this.refService.getCountryByIsoCode(citDocs.country.isoCountryCode);
              if (citDocs.country.isoCountryCode === app.primaryCitizenshipCountryIsoCode) {
                citDocs.country.isPrimary = true;
              }
            });

            let tempCountryList: Country[] = new Array<Country>();
            app.travelHistory.countries.forEach(country => {
              let tempCountry: Country = this.refService.getCountryByIsoCode(country.isoCountryCode);
              if (tempCountry) {
                tempCountryList.push(tempCountry);
              }
            });

            app.travelHistory.countries = tempCountryList;

            app.employments.forEach(employment => {
              // Self employed would not have a country so check for null.
              // NOTE should a country even be part of the model????
              if (employment.address.country.isoCountryCode) {
                employment.address.country =
                  this.refService.getCountryByIsoCode(employment.address.country.isoCountryCode);
              }
            });

            if (app.programCode === 'AB') {
              app.citizenshipDocuments.forEach(citDoc => {
                citDoc.documents = citDoc.documents.filter(doc => doc.type === 'PT');
              });
            }

            return app;
        }

        return null;
      }),
      catchError(error => this.errorHandler.handleError(error)));
  }
}
