

export class ManageVehicle {
    gesId: number;
    make: string;
    model: string;
    year: number;
    color: string;
    vin: string;
    licensePlateNumber: string;
    licenseCountryOfIssuance: string;
    govtLicensePlate: string;
    stateProvinceOfIssuance: string;
    owner: string;

    constructor(jsonObject: any = null) {
        if (jsonObject !== null) {
            if (jsonObject instanceof ManageVehicle) {
                this.gesId = jsonObject.gesId;
                this.make = jsonObject.make;
                this.model = jsonObject.model;
                this.year = jsonObject.year;
                this.color = jsonObject.color;
                this.vin = jsonObject.vin;
                this.licensePlateNumber = jsonObject.licensePlateNumber;
                this.licenseCountryOfIssuance = jsonObject.licenseCountryOfIssuance;
                this.govtLicensePlate = jsonObject.govtLicensePlate;
                this.stateProvinceOfIssuance = jsonObject.stateProvinceOfIssuance;
                this.owner = jsonObject.owner;

            } else {
                // this is a json object let's deserialize it
                if (jsonObject.hasOwnProperty('id')) {
                    this.gesId = jsonObject.gesId;
                }
                if (jsonObject.hasOwnProperty('color')) {
                    this.color = jsonObject.color;
                } else {
                    this.color = '';
                }
                if (jsonObject.hasOwnProperty('isGovernmentIssuedPlate')) {
                    if (typeof jsonObject.isGovernmentIssuedPlate === 'string') {
                        this.govtLicensePlate = (jsonObject.isGovernmentIssuedPlate === 'true') ? 'Y' : 'N';
                    } else if (typeof jsonObject.isGovernmentIssuedPlate === 'boolean') {
                        if (jsonObject.isGovernmentIssuedPlate) {
                            this.govtLicensePlate = 'Y';
                        } else {
                            this.govtLicensePlate = 'N';
                        }
                    } else {
                        this.govtLicensePlate = '';
                    }
                } else {
                    this.govtLicensePlate = '';
                }
                if (jsonObject.hasOwnProperty('licenseCountryOfIssuance')) {
                    this.licenseCountryOfIssuance = jsonObject.licenseCountryOfIssuance;
                } else {
                    this.licenseCountryOfIssuance = '';
                }
                if (jsonObject.hasOwnProperty('licensePlateNumber')) {
                    this.licensePlateNumber = jsonObject.licensePlateNumber;
                } else {
                    this.licensePlateNumber = '';
                }
                if (jsonObject.hasOwnProperty('make')) {
                    this.make = jsonObject.make;
                } else {
                    this.make = '';
                }
                if (jsonObject.hasOwnProperty('model')) {
                    this.model = jsonObject.model;
                } else {
                    this.model = '';
                }

                if (jsonObject.hasOwnProperty('ownerType')) {
                    this.owner = jsonObject.ownerType;

                } else {
                    this.owner = '';
                }

                if (jsonObject.hasOwnProperty('stateOfIssuance')) {
                    this.stateProvinceOfIssuance = jsonObject.stateOfIssuance;
                } else {
                    this.stateProvinceOfIssuance = '';
                }
                if (jsonObject.hasOwnProperty('vin')) {
                    this.vin = jsonObject.vin;
                } else {
                    this.vin = '';
                }
                if (jsonObject.hasOwnProperty('year')) {
                    this.year = Number(jsonObject.year);
                } else {
                    this.year = null;
                }
            }
        } else {
            this.gesId = 0;
            this.make = '';
            this.model = '';
            this.year = null;
            this.color = '';
            this.vin = '';
            this.licensePlateNumber = '';
            this.licenseCountryOfIssuance = '';
            this.govtLicensePlate = '';
            this.stateProvinceOfIssuance = '';
            this.owner = '';
        }
    }
}
