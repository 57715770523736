
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ErrorHandlerService } from './error-handler.service';

import { Document } from '../models/document.model';
import { environment } from '../../../environments/environment';

@Injectable()
export class DocumentVerificationService {

    constructor(private http: HttpClient,
        private errorHandler: ErrorHandlerService) { }

    public lookupDocuments(userId: String, documents : Document[]) : Observable<any> {
        if (!userId && !document) {
            return of(false);
        }

        let requestURI = environment.uriEndpoint + 'v1/goesapp/users/' + userId + '/verify/document/all/';

        let data = new Array<any>();

        for (let doc of documents) {
            // TODO use a model
            let countryCode = doc.countryOfIssuance.isoCountryCode || '';
            let item = {
                firstName : doc.givenName || '',
                lastName : doc.surName || '',
                dob: doc.dateOfBirth.year  +  '-' + doc.dateOfBirth.month + '-' + doc.dateOfBirth.day || '',
                documentNumber: doc.documentNumber || '',
                docType: doc.type || '',
                countryOfIssuance: countryCode || ''
            };
            data.push(item);
        }

        return this.http.post(requestURI, data).pipe(
            map((response:any) => {
                return response;
            }),
            catchError(error=> this.errorHandler.handleError(error)));
    }

    public testMethod() {
        return of('Hello');
    }
}
