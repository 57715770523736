import { UrlParam } from './url-param.model';
import { UrlParamHash } from './url-param-hash.interface';

export class UrlParams {

    private _params:UrlParamHash = {};

    constructor(){
        //
    }

    public add(param:UrlParam) {
        if(!this._params[param.key]){
            this._params[param.key] = param;
        } else {
            throw new Error("You may only add unique keys to this model");
        }
    }

    public remove(param:UrlParam) {
        if(this._params[param.key]){
            this._params[param.key] = null;
            delete this._params[param.key];
        }
    }

    public toString():string {
        let urlParamsString:string = '?';
        for(let key in this._params){
            urlParamsString += this._params[key].key + "=" + this._params[key].value + "&";
        }
        if(urlParamsString.length > 1){
            // assume at least one entry
            // trim last &
            urlParamsString = urlParamsString.substring(0, urlParamsString.length - 1);
        }
        return urlParamsString;
    }

}
