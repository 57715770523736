import { GoesDate } from './date.model';
import { Phone } from './phone';

export class SignUpPersonal{

    lastName : string;
    firstName : string;
    middleName : string;
    dateOfBirth : GoesDate;
    countryOfBirth : string;
    cityOfBirth : string;
    stateOfBirth : string;
    emailAddress : string;
    emailOptoutInd : boolean;
    confirmEmailAddress : string;
    phoneNumbers : Phone[] = [];
    appliedTPP : string;
    appliedFast : string;
    appliedBefore : String;
    passId : string;
    fastId : string = "";
    userId : string;
    goesId : string = "";
    questionList : any [] = [];
    maxAttempt : number;
    maxDocAttempt : number;
    answerSuccess : boolean;
    linkedAccountEmail : string;
    documentInfo : any = {
        docType : "",
        docNumber :  "",
        countryOfIssuance : ""
    };

    constructor(){
        this.dateOfBirth = new GoesDate();
    }

}
