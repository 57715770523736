export class UrlParam {

    private _key:string;
    public get key():string {
        return this._key;
    }
    private _value:string;
    public get value():string {
        return this._value;
    }

    /**
     * UrlParam constructor
     * @param key
     * @param value only string, boolean, or number accepted
     */
    constructor(key:string, value:any) {
        this._key = key;
        if(typeof value === 'string') {
            this._value = value;
        } else if(typeof value === 'boolean') {
            this._value = value? 'true' : 'false';
        } else if(typeof value === 'number') {
            this._value = value.toString();
        } else {
            throw new Error('Unsupported value of '+ typeof value +' set for url param model');
        }
    }

}
